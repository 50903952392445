<template>
  <!-- <content-title title="新闻资讯" /> -->
  <!-- 内容 -->
  <div class="search-result" v-if="!!$route.query.q">
    <div class="search-input">
      <input type="text" class="input" v-model.trim="data.searchValue">
      <el-button type="success" :icon="Search" @click="handlerClick" />
    </div>

    <h1>您的搜索“<span>{{ $route.query.q }}</span>”的结果为：</h1>
  </div>
  <div class="news-content">
    <ul class="list" v-if="data.newsList.length > 0">
      <li v-for="item in data.newsList" :key="item.id">
        <div class="news-item" @click="handlerJump(item)">
          <div class="flex">
            <div class="icon">
              <div class="news-topping" v-if="item.is_new || item.is_top">
                <svg-icon name="news" class-name="w-55px h-30px" v-if="item.is_new" />
                <svg-icon name="topping" class-name="w-55px h-30px" v-if="item.is_top" />
              </div>
              <div class="arrow-drop" v-else>
                <svg-icon name="arrow-drop" class-name="w-12px h-23px" />
              </div>
            </div>
            <div class="describe">
              <p>{{ item.title_en }}</p>
            </div>
          </div>
          <div class="time">
            <span>{{ item.created_at }}</span>
          </div>
        </div>
      </li>
    </ul>

    <el-empty description="暂无内容" v-else />
  </div>
  <!-- 分页 -->
  <div class="paging" v-show="data.newsList.length > 0">
    <el-pagination background layout="pager" :page-size="20" :total="data.total" @current-change="handlerChange" />
  </div>
</template>

<script setup>
import { Search } from '@element-plus/icons-vue'
// import contentTitle from '@/layout/component/contentTitle.vue'
import { reactive, onMounted, watch } from 'vue'
// api
import { apiNewsList } from '@/api/list.js'
import useParams from '@/hooks/getParams.js'
import { useRoute, useRouter } from 'vue-router'
// 获取url中的参数
let params = useParams()
let data = reactive({
  page: 1,
  limit: 20,
  total: 0, // 总条数
  newsList: [], // 新闻数据列表
  searchValue: '', //搜索结果
})
// 获取新闻数据
let $route = useRoute()
let $router = useRouter()
const handlerNews = async () => {
  let value = {}

  if ($route.query.q) {
    value = {
      page: data.page,
      limit: data.limit,
      keywords: $route.query.q,
      is_en: 3
    }
  } else {
    value = {
      page: data.page,
      limit: data.limit,
      type_id: $route.query.q ? '' : params.detailsId,
      keywords: $route.query.q,
      is_en: 3
    }
  }

  let { code, data: res } = await apiNewsList(value)
  if (code == 200) {
    data.newsList = res.data
    data.total = res.total
  }
}
// 分页条数改变
const handlerChange = (num) => {
  data.page = num
  handlerNews()
}
onMounted(() => {
  handlerNews()
})
// 跳转到目标页去
const handlerJump = ({ id, type_id }) => {
  $router.push({
    name: "detile",
    params: {
      detailsId: id,
      navId: type_id
    }
  })
}
// 搜索
const handlerClick = () => {
  if (!data.searchValue) return
  $router.push({
    path: "/edps/news/-3",
    query: {
      q: data.searchValue,
    },
  })
}

watch(() => $route.query.q, () => {
  data.page = 1;
  data.limit = 20
  handlerNews()
})
</script>

<style lang="scss" scoped>
@import '@/style/paging.scss';
@media screen and (min-width: $media) {
  .search-result {
    margin-bottom: .1563rem;
    .search-input {
      display: flex;
      justify-content: space-between;
      height: .3125rem;
      margin-bottom: .1563rem;

      .input {
        width: 94%;
        height: 100%;
        padding: 0px .0781rem;
        border-radius: .0391rem;

      }

      input {
        border: 1px solid #ccc;
        outline: none;
      }

      .ivu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: #a6291a;

        span {
          display: inline-block;
          line-height: .1094rem;
        }

        :deep(.el-icon) {
          color: #fff;
        }

        :hover {
          border: none;
        }
      }
    }

    h1 {
      font-weight: 500;

      span {
        color: #a6291a;
      }
    }
  }

  .news-content {
    .list {
      >li {
        width: 100%;
        height: .5781rem;
        background: transparent;
        opacity: 1;
        border-bottom: .0078rem dashed #bababa;
        margin-bottom: .0234rem;
        cursor: pointer;

        .news-item {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px .2969rem 0px .1484rem;
          // flex: 1;

          .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: .4297rem;
              height: 100%;
              margin-right: .1953rem;

              .news {
                opacity: 1;
              }

              .news-topping {
                .svg-box {
                  width: .4297rem;
                  height: .2344rem;
                }
              }

              .arrow-drop {
                .svg-box {
                  width: .0938rem;
                  height: .1797rem;
                }
              }
            }

            .describe {
              width: 100%;
              height: .2656rem;
              flex: 1;
              line-height: .2656rem;
              @include ellipsis(1);

              p {
                font-size: .1719rem;
                font-weight: 500;
                color: #5a5a5a;
                text-align: left;
              }
            }
          }

          .time {
            white-space: nowrap;
            margin-left: .3125rem;

            >span {
              display: inline-block;
              height: .1719rem;
              font-size: .1719rem;
              font-weight: 500;
              color: #5a5a5a;
            }
          }
        }

        &:hover {
          // box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.44);
          box-shadow:
            rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
            rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
          border-bottom: 0px dashed #bababa;
          // margin-bottom: 3px;
        }
      }
    }
  }
}

@media screen and (max-width: $media) {
  .search-result {
    margin-bottom: .26rem;
    .search-input {
      display: flex;
      justify-content: space-between;
      height: 1.4rem;
      margin-bottom: .2rem;

      .input {
        width: 80%;
        height: 100%;
        padding: 0px .4rem;
        border-radius: .1rem;
        font-size: .52rem;
      }

      input {
        border: 1px solid #ccc;
        outline: none;
      }

      .ivu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: #a6291a;

        span {
          display: inline-block;
          line-height: .1094rem;
        }

        :deep(.el-icon) {
          color: #fff;
        }
      }
    }

    h1 {
      font-weight: 500;
      font-size: .6rem;

      span {
        color: #a6291a;
      }
    }
  }
  .list {
    >li {
      width: 100%;
      height: 1.2rem;
      background: transparent;
      opacity: 1;
      border-bottom: .0078rem dashed #bababa;
      margin-bottom: .0234rem;
      cursor: pointer;

      .news-item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px .2969rem 0px .1484rem;
        // flex: 1;

        .flex {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: .6rem;
            height: 100%;
            margin-right: .2rem;

            .news {
              opacity: 1;
            }

            .news-topping {
              .svg-box {
                width: 1rem;
                height: 1rem;
              }
            }

            .arrow-drop {
              .svg-box {
                width: .2rem;
                height: .2rem;
              }
            }
          }

          .describe {
            width: 7rem;
            height: .7rem;
            flex: 1;
            line-height: .7rem;
            @include ellipsis(1);

            p {
              font-size: .52rem;
              font-weight: 500;
              color: #5a5a5a;
              text-align: left;
            }
          }
        }

        .time {
          display: flex;
          align-items: center;
          white-space: nowrap;
          line-height: 1.08rem;

          >span {
            // display: inline-block;
            // height: 100%;
            font-size: .52rem;
            font-weight: 500;
            color: #5a5a5a;
          }
        }
      }

      &:hover {
        // box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.44);
        box-shadow:
          rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
          rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        border-bottom: 0px dashed #bababa;
        // margin-bottom: 3px;
      }
    }
  }
}
</style>
